

































import Vue from "vue";

interface FormationItem {
  image?: string;
  year: string;
  label: string;
  description: string;
  location: string;
}

export default Vue.extend({
  name: "Formation",
  data: () => ({
    formations: [
      {
        year: "2015-2019",
        location: "Polytech' Nice Sophia",
        label: "Diplômes Ingénieur et Master Informatique",
        description:
          "Double diplôme : <ul><li>Ingénieur en Sciences Informatiques, spécialité Interfaces Homme-Machine</li><li>Master Informatique et Ingénierie (IFI) en apprentissage</li></ul>",
        image:
          "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fyt3.ggpht.com%2Fa-%2FAN66SAwGLGtyguZTlbiaMqRaWB38rDfR-OUrb_rNDA%3Ds900-mo-c-c0xffffffff-rj-k-no&f=1&nofb=1"
      },
      {
        year: "2013-2015",
        location: "Polytech' Annecy Chambéry",
        label: "PeiP - Option Informatique",
        description: "Parcours des écoles d'ingénieurs Polytech.",
        image:
          "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fyt3.ggpht.com%2Fa-%2FAN66SAwGLGtyguZTlbiaMqRaWB38rDfR-OUrb_rNDA%3Ds900-mo-c-c0xffffffff-rj-k-no&f=1&nofb=1"
      },
      {
        year: "2013",
        location: "Lycée Vaugelas - Chambéry",
        label: "BAC S - SVT",
        description: "Mention Bien.",
        image: "img/vaugelas.jpg"
      }
    ] as FormationItem[]
  })
});
